// ==========[ GENERAL ]==========

* {
  font-feature-settings: "kern"1;
  font-family          : 'Helvetica',
    sans-serif;
  font-kerning           : normal;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin : 0;
  padding: 0;
}

h1 {
  color         : $green-dark;
  text-transform: uppercase;
  font-family   : $ff-aero-matics-display;
  font-size     : 42px;

  span {
    font-family: $ff-aero-matics-display;
  }
}

h2 {
  text-transform: uppercase;
  font-family   : $ff-aero-matics-display;
  font-size     : 32px;

  span {
    font-family: $ff-aero-matics-display;
  }
}

h3 {
  // style h3
}

h4 {
  font-size  : 18px;
  font-weight: bold;
  line-height: 27px;
}

h5 {
  font-size  : 16px;
  font-weight: bold;
  line-height: 24px;
}

.cms,
p {
  color      : $black;
  font-size  : 16px;
  font-weight: normal;
  line-height: 24px;

  a {
    color          : $green;
    text-decoration: underline;

    &:hover {
      color          : $green-dark;
      text-decoration: none;
    }
  }
}

// ==========[ FORMS ]==========

.form-group {
  label {
    color      : $black;
    font-size  : 16px;
    font-weight: bold;
  }

  .form-control {
    border          : 2px solid $grey-light;
    border-radius   : 0;
    background-color: transparent;

    &:focus {
      border    : 2px solid $green-light;
      outline   : 0;
      box-shadow: none;
    }

    &.datum,
    &.tijd {
      background-repeat  : no-repeat;
      background-position: 95% center;
    }

    &.datum {
      background-image: url(../images/datum-icon.png);
    }

    &.tijd {
      background-image: url(../images/tijd-icon.png);
    }
  }

  .error {
    color       : red;
    border-color: red;
  }
}

.error {
  border : 2px solid red;
  padding: 5px;
}

.custom-control {
  label {
    font-size  : 15px;
    font-weight: normal;
  }
}

// ==========[ HEADER / NAVIGATIE ]==========

.navbar {
  position        : relative;
  padding-top     : 50px;
  padding-bottom  : 50px;
  background-color: $beige;

  .container {
    position: relative;
  }

  .nav-item {
    margin-left: 10px;

    &.--social {
    	.nav-link {
    		font-size: 22px;
    		padding: 0;
    	}
    }

    .nav-link {
      color      : $black;
      font-size  : 16px;
      font-weight: bold;
    }

    &:first-child {
      margin-left: 0;
    }

    &.active,
    &:hover {
      .nav-link {
        color: $green;
      }
    }
  }

  .top-bar {
    display        : flex;
    position       : absolute;
    top            : -20px;
    right          : 23px;
    align-items    : center;
    justify-content: flex-end;

    a {
      color      : $black;
      font-size  : 14px;
      font-weight: bold;

      &:hover {
        color          : $green;
        text-decoration: none;
      }
    }

    p {
      color      : $black;
      font-size  : 14px;
      font-weight: bold;
    }
  }
}

// ==========[ FOOTER ]==========

footer {
  background-color: $beige;

  ul {
    margin         : 0;
    padding        : 0;
    list-style-type: none;

    li {
      margin-bottom: 10px;

      a {
        color          : $black;
        text-decoration: none;
        font-size      : 16px;
        font-weight    : bold;

        &.active,
        &:hover {
          color          : $green;
          text-decoration: none;
        }
      }
    }
  }
}

.sub-footer {
  padding         : 30px 0;
  background-color: $green-dark;

  p {
    color      : $green;
    font-size  : 16px;
    font-weight: bold;

    a {
      &:hover {
        color          : $beige;
        text-decoration: none;
      }
    }
  }
}

// ==========[ HOME ]==========

.home-slider-wrapper {
  position: relative;
  height  : 500px;

  .home-slider-controls-wrapper {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    min-height: 500px;

    .home-slider-controls {
      display        : flex;
      min-height     : 100%;
      height         : 100%;
      align-items    : center;
      justify-content: space-between;

      a {
        z-index         : 1;
        display         : flex;
        width           : 40px;
        height          : 40px;
        color           : $white;
        background-color: $green;
        align-items     : center;
        justify-content : center;
        transition      : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

        &:hover {
          background-color: $green-dark;
          text-decoration : none;
        }
      }
    }
  }

  .home-slider-slides {
    height: 500px;

    .home-slider-slide {
      height             : 500px;
      background-repeat  : no-repeat;
      background-position: center;
      background-size    : cover;

      .container .row {
        height: 500px;
      }

      .home-slider-cta {
        display         : flex;
        padding         : 30px 40px;
        color           : $white;
        background-color: $green;
        align-items     : center;
        justify-content : space-between;
        transition      : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

        &:hover {
          background-color: $green-dark;
          text-decoration : none;
        }
      }
    }
  }
}

.cta-row {
  width: 100%;
}

// ==========[ PROJECTEN DETAIL ]==========

.project-getuigenis {
  padding: 50px 100px;
  border : 2px solid $grey-light;

  .getuigenis-naam {
    color      : $grey;
    font-family: $ff-noemie-script;
    font-size  : 40px;
  }

  .getuigenis-bedrijf {
    color      : $grey;
    font-size  : 16px;
    font-style : italic;
    font-weight: normal;
  }
}

// ==========[ CONTACT ]==========

.map {
  width      : 100%;
  padding-top: 100%;
}