// ==========[ OFF CANVAS MENU ]==========

.no-scrolling {
  height  : 100%;
  overflow: hidden;
}

.navbar-toggler {
  display        : flex;
  color          : $black;
  align-items    : center;
  justify-content: flex-start;

  p {
    color: $black;
  }
}

.navbar,
.navbar>.container,
.navbar>.container-fluid {
  align-items: flex-end;
}

.off-canvas-nav {
  z-index        : 9999;
  z-index        : 2;
  display        : flex;
  position       : fixed;
  top            : 0;
  right          : -520px;
  bottom         : 0;
  width          : 520px;
  padding        : 40px;
  border-left    : 2px solid $grey-light;
  background     : $white;
  align-items    : flex-start;
  flex-direction : column;
  justify-content: space-between;
  transition     : right 500ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

  section {
    display        : flex;
    width          : 100%;
    align-items    : flex-start;
    flex-direction : column;
    justify-content: flex-start;

    .burger-menu {
      align-self: flex-end;
    }
  }

  ul {
    margin : 0;
    padding: 0;

    li {
      margin    : 20px 0;
      list-style: none;

      a {
        color      : $black;
        font-size  : 16px;
        font-weight: 700;

        &.active {
          color      : $green;
          font-weight: 700;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &.active {
    display   : flex;
    right     : 0;
    box-shadow: -10px 0px 80px 0px rgba(0, 0, 0, 0.25);
  }

  .languages {
    a {
      color          : $grey;
      text-decoration: none;
      text-transform : uppercase;
      font-size      : 16px;
      font-weight    : bold;

      &.active {
        color: $green;
      }
    }
  }
}

.fullscreen-overlay {
  z-index         : 1;
  position        : absolute;
  top             : 0;
  left            : 0;
  width           : 0;
  height          : 0;
  background-color: transparentize(#000000, 1);
  transition      : background-color 500ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

  &.active {
    top             : 0;
    right           : 0;
    bottom          : 0;
    left            : 0;
    width           : auto;
    height          : auto;
    background-color: transparentize(#000000, .5);
  }
}

// ==========[ BUTTONS ]==========

.btn {
  padding      : 7px 17px;
  border-radius: 0;
  font-size    : 16px;
  font-weight  : bold;
}

.btn-primary {
  color           : $white;
  border          : 2px solid $green;
  background-color: $green;

  &:hover {
    color           : $white;
    border          : 2px solid $green-dark;
    background-color: $green-dark;
  }
}

.btn-secondary {
  color           : $green;
  border          : 2px solid $green;
  background-color: transparent;

  &:hover {
    color           : $white;
    border          : 2px solid $green-dark;
    background-color: $green-dark;
  }
}

.btn-white {
  color           : $white;
  border          : 2px solid $white;
  background-color: transparent;

  &:hover {
    color           : $green;
    border          : 2px solid $white;
    background-color: $white;
  }
}

.btn-back {
  color      : $grey;
  font-weight: bold;

  &:hover {
    color          : $black;
    text-decoration: none;
  }
}

// ==========[ HELPER CLASSES ]==========
.url {
  display: none;
}

.c-green {
  color: $green;
}

.c-green-dark {
  color: $green-dark;
}

.c-grey {
  color: $grey;
}

.social {
  color    : $black;
  font-size: 18px;

  &:hover {
    color          : $green;
    text-decoration: none;
  }
}

.bottom-nav {

  .cms,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: $grey;
  }

  .social {
    color: $grey;

    &:hover {
      color: $green;
    }
  }
}

.bordered {
  border: 2px solid $grey-light;
}

// ==========[ NIEUWS ]==========

.nieuws-item {
  display   : block;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, .15);
  transition: all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

  .image {
    width              : 100%;
    padding-top        : 50%;
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : cover;
  }

  .content {
    position: relative;
    padding : 40px;

    .button {
      display         : flex;
      position        : absolute;
      top             : -20px;
      right           : 40px;
      width           : 40px;
      height          : 40px;
      color           : $white;
      background-color: $green;
      align-items     : center;
      justify-content : center;
      transition      : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
    }

    .nieuws-datum {
      color    : $grey;
      font-size: 16px;
    }

    .nieuws-titel {
      color      : $black;
      font-size  : 18px;
      font-weight: bold;
      transition : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
    }
  }

  &:hover {
    box-shadow     : 0px 10px 30px -10px rgba(0, 0, 0, 0.15);
    text-decoration: none;
    transform      : scale(1.03);

    .button {
      background-color: $green-dark;
    }

    .nieuws-titel {
      color: $green-dark;
    }
  }
}

// ==========[ BLOCKS ]==========

.image-block {
  .image {
    position           : relative;
    width              : 100%;
    padding-top        : 75%;
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : 110%;
    transition         : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

    .overlay {
      display        : flex;
      position       : absolute;
      top            : 0;
      right          : 0;
      bottom         : 0;
      left           : 0;
      align-items    : center;
      justify-content: center;

      .button {
        display         : flex;
        width           : 40px;
        height          : 40px;
        color           : $white;
        background-color: $green;
        align-items     : center;
        justify-content : center;
        transition      : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
      }
    }
  }

  h4 {
    color     : $black;
    transition: all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  }

  &:hover {
    text-decoration: none;

    .image {
      background-size: 105%;

      .overlay {
        .button {
          background-color: $green-dark;
          box-shadow      : 0px 10px 30px -10px rgba(0, 0, 0, .3);
          transform       : scale(1.2);
        }
      }
    }

    h4 {
      color: $green-dark;
    }
  }
}

.text-block {
  display         : flex;
  height          : 77%;
  background-color: $green;
  align-items     : center;
  flex-direction  : column;
  justify-content : center;
  padding-top     : 24%;
  padding-bottom  : 24%;

  .cms,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: $white;
  }
}

.text-block-small {
  display         : flex;
  padding         : 50px;
  background-color: $green;
  align-items     : center;
  flex-direction  : column;
  justify-content : center;

  .cms,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: $white;
  }
}

.bg-block {
  position           : relative;
  background-repeat  : no-repeat;
  background-position: center;
  background-size    : cover;

  .overlay {
    z-index         : -1;
    position        : absolute;
    top             : 0;
    right           : 0;
    bottom          : 0;
    left            : 0;
    background-color: transparentize($black, .8);
  }

  .container {
    z-index        : 999;
    display        : flex;
    align-items    : center;
    flex-direction : column;
    justify-content: center;
  }

  .cms,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: $white;
  }
}

.image-with-hover {
  display            : block;
  position           : relative;
  width              : 100%;
  padding-top        : 75%;
  background-repeat  : no-repeat;
  background-position: center;
  background-size    : 105%;
  transition         : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

  .button {
    display         : flex;
    position        : absolute;
    top             : 0;
    right           : 0;
    width           : 40px;
    height          : 40px;
    color           : $white;
    background-color: $green;
    align-items     : center;
    justify-content : center;
    transition      : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  }

  &:hover {
    background-size: 100%;

    .button {
      width           : 55px;
      height          : 55px;
      background-color: $green-dark;
      font-size       : 22px;
    }
  }
}

.verhuur-item {
  .content {
    display        : flex;
    justify-content: space-between;

    .left {
      flex: 1 0 66%;
    }

    .right {
      display        : flex;
      align-items    : center;
      flex           : 1 0 33%;
      justify-content: flex-end;
    }

    .verhuur-aantal {
      padding-top   : 8px;
      padding-bottom: 8px;
      color         : $black;
      border        : 2px solid $grey-light;
      text-align    : center;
      font-size     : 16px;
      font-weight   : bold;
      width         : 45px;
    }
  }
}

// ==========[ FULL WIDTH SLIDER ]==========

.full-width-slider {
  position: relative;
  width   : 100%;
  height  : 500px;

  .slider-controls-wrapper {
    position  : absolute;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    min-height: 500px;

    .slider-controls {
      display        : flex;
      min-height     : 100%;
      align-items    : center;
      justify-content: space-between;

      a {
        z-index         : 1;
        display         : flex;
        width           : 40px;
        height          : 40px;
        color           : $white;
        background-color: $green;
        align-items     : center;
        justify-content : center;
        transition      : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

        &:hover {
          background-color: $green-dark;
          text-decoration : none;
        }
      }
    }
  }

  .full-width-slider-slides {
    min-height: 500px;

    .full-width-slider-slide {
      min-height         : 500px;
      background-repeat  : no-repeat;
      background-position: center;
      background-size    : cover;
    }
  }
}