// ==========[ FONTS ]==========

@font-face {
  font-family : 'Aero Matics Display';
  font-weight : 300;
  font-style  : normal;
  src         : url(../fonts/AeroMaticsDisplayLightRegular.woff);
  src         : url(../fonts/AeroMaticsDisplayLightRegular.woff2);
}

@font-face {
  font-family : 'Aero Matics Display';
  font-weight : 300;
  font-style  : italic;
  src         : url(../fonts/AeroMaticsDisplayLightItalic.woff);
  src         : url(../fonts/AeroMaticsDisplayLightItalic.woff2);
}

@font-face {
  font-family : 'NoemieScript';
  font-weight : normal;
  font-style  : normal;
  src         : url(../fonts/NoemieScriptRegular.woff);
  src         : url(../fonts/NoemieScriptRegular.woff2);
}

$ff-aero-matics-display : 'Aero Matics Display', sans-serif;
$ff-noemie-script       : 'NoemieScript', sans-serif;

// ==========[ COLORS ]==========

$white       : #FFFFFF;
$grey-light  : #F2F2F2;
$grey        : #939598;
$black       : #414042;

$beige       : #F9F8EF;
$green-light : #D2D3A8;
$green       : #ABAD08;
$green-dark  : #7A7B00;
